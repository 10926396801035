$sans: proxima-nova, sans-serif;
$serif: georgiapro, serif;
$header-height: 65px;
$footer-height: 90px;

// ie fallback
$primary: #AB1A2D;
$primary-variant: #9E0D20;
$secondary: #FEBE2A;
$secondary-variant: #FFCB37;
$background: #FFF;
$background-opacity: rgb(255,255,255);
$surface: #F2F2F2;
$accent: #C3C3C3;
$accent-variant: #909090;
$error: #BB1D30;
$error-variant: #A20417;
$on-background: #3B3B3B;
$on-surface: #555;
$on-surface-variant: #3B3B3B;
$on-primary: #FFF;
$on-secondary: #3B3B3B;
$on-error: #FFF;

html[data-theme="light"] {
	--primary: #AB1A2D;
	--primary-variant: #9E0D20;
	--secondary: #FEBE2A;
	--secondary-variant: #FFCB37;
	--background: #FFF;
	--background-opacity: 255,255,255;
	--surface: #F2F2F2;
	--accent: #C3C3C3;
	--accent-variant: #909090;
	--error: #BB1D30;
	--error-variant: #A20417;
	--on-background: #3B3B3B;
	--on-surface: #555;
	--on-surface-variant: #3B3B3B;
	--on-primary: #FFF;
	--on-secondary: #3B3B3B;
	--on-error: #FFF;
}

html[data-theme="dark"] {
	--primary: #F8677A;
	--primary-variant: #FF7386;
	--secondary: #FEBE2A;
	--secondary-variant: #FFCB37;
	--background: #1C1C1D;
	--background-opacity: 28,28,29;
	--surface: #29292A;
	--accent: #555;
	--accent-variant: #7B7B7B;
	--error: #E14356;
	--error-variant: #EE5063;
	--on-background: #C3C3C3;
	--on-surface: #C3C3C3;
	--on-surface-variant: #D0D0D0;
	--on-primary: #1C1C1D;
	--on-secondary: #3B3B3B;
	--on-error: #FFF;
}