form {
	font-family: $sans;

	.field {
		position: relative;

		label {
			position: absolute;
			top: 7px;
			left: 11px;
		}
	}

	input {
		accent-color: $primary;
		accent-color: var(--primary);
	}

	input:not([type=radio]), textarea {
		width: 100%;
		display: block;
		margin-bottom: 1rem;
		border: 1px solid $accent;
		border: 1px solid var(--accent);
		padding: 26px 10px 8px;
		border-radius: 3px;
		font-size: 0.9rem;
		font-family: $sans;
		outline: none;
		background-color: $surface;
		background-color: var(--surface);
		color: $on-surface;
		color: var(--on-surface);

		&:focus {
			border-color: $primary;
			border-color: var(--primary);

			+ label {
				color: $primary;
				color: var(--primary);
			}
		}

		&:disabled {
			background-color: $accent;
			background-color: var(--accent);
			color: $accent-variant;
			color: var(--accent-variant);
			border-color: $accent;
			border-color: var(--accent);

			+ label {
				color: $accent-variant;
				color: var(--accent-variant);
			}
		}

		&::placeholder {
			color: $accent;
			color: var(--accent);
		}
	}

	label {
		color: $accent-variant;
		color: var(--accent-variant);
		font-size: 0.75rem;
		font-family: $sans;

		&.radio {
			display: block;
			font-size: 0.9rem;
			color: $on-background;
			color: var(--on-background);
			cursor: pointer;

			input[type=radio] {
				margin: 0 0.75rem 0 0;
				cursor: pointer;

				+ span {
					line-height: 2rem;
				}
			}
			
		}
	}
}

input[type=submit], button, a.cta {
	background-color: $primary;
	background-color: var(--primary);
	color: $on-primary;
	color: var(--on-primary);
	padding: 7px 16px 8px;
	text-align: center;
	border: none;
	font-size: 0.9rem;
	font-family: $sans;
	font-weight: 500;
	border-radius: 100px;
	text-decoration: none;
	margin-bottom: 1rem;
	position: relative;
	overflow: hidden;
	cursor: pointer;

	&:after {
		content: '';
		display: none;
		position: absolute;
		border-radius: 100%;
		background-color: rgba(255,255,255,0.15);
		width: 100px;
		height: 100px;
		margin-top: -50px;
		margin-left: -50px;
		top: 50%;
		left: 50%;
		animation: ripple 1s;
		opacity: 0;
	}

	&:focus:not(:active)::after {
		display: block;
	}

	&:active, &:focus {
		border: none;
		outline: none;
	}

	&:hover {
		background-color: $primary-variant;
		background-color: var(--primary-variant);
	}

	&:disabled {
		background-color: $accent;
		background-color: var(--accent);
		cursor: default;
	}

	&.danger {
		background-color: $error;
		background-color: var(--error);
		color: $on-error;
		color: var(--on-error);

		&:hover {
			background-color: $error-variant;
			background-color: var(--error-variant);
		}
	}
}

@keyframes ripple {
	from {
		opacity: 1;
		transform: scale(0);
	}

	to {
		opacity: 0;
		transform: scale(10);
	}
}