section {
	.wrapper {
		&.collection {
			.collection-header {
				display: flex;
				margin-bottom: 2rem;
				align-items: center;
				@include bp(mobile) {
					display: block;
				}

				.collection-icon {
					width: 100px;
					height: 100px;
					margin-right: 1.5rem;
					@include bp(mobile) {
						margin: 0 auto 1rem;
					}
				}

				h1 {
					margin-bottom: 0;
					@include bp(mobile) {
						text-align: center;
					}
				}
			}

			.collection-body {
				position: relative;

				:last-child {
					margin-bottom: 0;
				}

				p {
					&.sl, &.sll {
						margin-left: 1.5rem;
						text-indent: -1.5rem;
						margin-bottom: 0;
					}

					&.sl1, &.sl1l {
						margin-left: 3rem;
						text-indent: -1.5rem;
					}

					&.sl2, &.sl2l {
						margin-left: 4.5rem;
						text-indent: -1.5rem;
					}

					&.sl, &.sl1, &.sl2 {
						margin-bottom: 0;
					}

					&.sll, &.sl1l, &.sl2l {
						margin-bottom: 1rem;;
					}

					&.fn {
						display: none;
					}
				}
			}
		}
	}
}

.collection-icon {
	background-position: center;
	background-repeat: no-repeat;
	@supports(mask-repeat: no-repeat) {
		mask-repeat: no-repeat;
		-webkit-mask-repeat: no-repeat;
		background: var(--primary) !important;
		mask-position: center;
		-webkit-mask-position: center;
	}
}