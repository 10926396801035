ul {
	&.collections-list {
		list-style-type: none;
		padding-left: 0;
		margin: 0 -10px;

		li {
			border-radius: 4px;
			padding: 10px;

			&:hover {
				background-color: $surface;
				background-color: var(--surface);
			}

			a {
				width: 100%;
				display: flex;
				align-items: center;
				font-size: 1.4rem;
				color: $on-background;
				color: var(--on-background);
				@include bp(mini) {
					font-size: 1.1rem;
				}
			}

			.collection-icon {
				flex-shrink: 0;
				width: 80px;
				height: 80px;
				margin-right: 1.25rem;
				@include bp(mini) {
					width: 60px;
					height: 60px;
				}
			}
		}
	}
}