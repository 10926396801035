h1 {
	font-family: $serif;
	font-weight: 500;
	font-size: 2.2rem;
	margin: 0 0 1rem;
	line-height: 1.2;
	color: $on-background;
	color: var(--on-background);

	@include bp(sm) {
		font-size: 1.8rem;
	}
}

h2 {
	font-family: $serif;
	font-weight: 500;
	font-size: 1.5rem;
	margin: 0;
	color: $on-background;
	color: var(--on-background);
	border-bottom: 2px solid $primary;
	border-bottom: 2px solid var(--primary);
	padding-bottom: 0.5rem;
	margin: 2rem 0 1rem;

	@include bp(sm) {
		font-size: 1.2rem;
	}
}

h3 {
	font-family: $sans;
	font-weight: 500;
	font-size: 1rem;
	margin: 0;
}

h5 {
	font-family: $serif;
	font-style: italic;
	font-size: 1rem;
	font-weight: 500;
	margin: 0.75rem 0 1rem;
}

h3, h4, h5 {
	color: $primary;
	color: var(--primary);
}

p, ul, ol {
	margin: 0 0 1rem;
	color: $on-background;
	color: var(--on-background);

	&:last-child {
		margin-bottom: 0;
	}
}

ol, ul {
	padding-left: 1.25rem;
}

a {
	text-decoration: none;
	color: $primary;
	color: var(--primary);
	cursor: pointer;

	&:hover {
		color: $primary-variant;
		color: var(--primary-variant);
	}
}

.no-wrap {
	white-space: nowrap;

	@include bp(mini) {
		white-space: normal;
	}
}