.spinner {
	width: 100%;
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	text-align: center;
	z-index: 4;

	p {
		font-family: $sans;
		text-transform: uppercase;
		font-weight: bold;
		font-size: 85%;
		margin-top: 5px;
	}

	.loader {
		margin: 0 auto;
		width: 80px;
		text-align: center;
		
		> div {
			width: 12px;
			height: 12px;
			margin: 0 4px;
			background-color: $primary;
			background-color: var(--primary);
			border-radius: 100%;
			display: inline-block;
			-webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
			animation: sk-bouncedelay 1.4s infinite ease-in-out both;
		
			&.bounce1 {
				-webkit-animation-delay: -0.32s;
				animation-delay: -0.32s;
			}

			&.bounce2 {
				-webkit-animation-delay: -0.16s;
				animation-delay: -0.16s;
			}
		}
	}
}

@-webkit-keyframes sk-bouncedelay {
	0%,
	80%,
	100% {
		-webkit-transform: scale(0)
	}
	40% {
		-webkit-transform: scale(1.0)
	}
}

@keyframes sk-bouncedelay {
	0%,
	80%,
	100% {
		-webkit-transform: scale(0);
		transform: scale(0);
	}
	40% {
		-webkit-transform: scale(1.0);
		transform: scale(1.0);
	}
}