@import './mixins';
@import './vars';
@import './typography';
@import './navigation';
@import './form';
@import './collections-list';
@import './collection';
@import './modals';
@import './spinner';
@import './dark';

* {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	-ms-box-sizing: border-box;
	box-sizing: border-box;
}

* img {
	max-width: 100%;
	max-height: 100%;
	display: block;
}

::selection {
	background-color: $primary;
	background-color: var(--primary);
	color: $on-primary;
	color: var(--on-primary);
}

html {
	font-size: 17px;
	-webkit-text-size-adjust: 100%;
}

html, body {
	max-width: 100%;
	overflow-x: hidden;
	touch-action: pan-y;
}

body {
	font-family: $serif;
	font-weight: 400;
	line-height: 1.4;
	width: 100%;
	position: relative;
	margin: 0 auto;
	background-color: $background;
	background-color: var(--background);
	letter-spacing: 0;
	
	#root {
		position: relative;

		main {
			&:not(.maintenance) {
				@include bp(mobile) {
					margin-top: $header-height;
					margin-bottom: $footer-height;
				}
			}
		}

		.maintenance {
			section {
				min-height: 100vh;
				background-color: $background;
				background-color: var(--background);
				display: flex;
				flex-direction: column;
				justify-content: center;

				.wrapper {
					width: 800px;
					max-width: 100%;
					text-align: center;
					margin-top: 10px;
					margin-bottom: 30px;

					.logo {
						width: 80px;
						height: 131px;
						margin: 0 auto 1rem;
					}

					h1, h2 {
						color: $on-surface;
						color: var(--on-surface);
						font-weight: 400;
						margin: 0 auto 0.5rem;
					}
				}
			}
		}
	}
}


section {
	padding: 40px 40px 80px;
	@include bp(mobile) {
		padding: 40px;
	}
	@include bp(mini) {
		padding: 30px 20px 40px;
	}

	.wrapper {
		max-width: 800px;
		margin: 0 auto;
	}
}

.account {
	h2 {
		&:last-of-type {
			margin-bottom: 1rem;
		}
	}
}

.actions {
	margin: 2rem 0;
	text-align: center;

	&:last-child {
		margin-bottom: 0;
	}

	.cta {
		display: inline-block;
		margin-bottom: 0;
	}
}

.error-message {
	font-family: $serif;
	color: $error;
	color: var(--error);
}