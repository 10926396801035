.modals {
	position: fixed;
	bottom: 20px;
	left: 50%;
	transform: translateX(-50%);
	width: 100%;
	padding: 0 40px;
	z-index: 4;
	@include bp(mobile) {
		bottom: calc(20px + #{$footer-height});
	}
	
	@include bp(mini) {
		padding: 0 20px;
	}

	.modal {
		display: none;
		font-family: $sans;
		font-size: 0.8em;
		color: #fff;
		text-align: center;
		max-width: 800px;
		padding: 20px;
		margin: 1rem auto 0;
		background-color: rgba(53,54,58,0.9);
		border: 1px solid #202124;
		border-radius: 6px;
		filter: drop-shadow(2px 4px 6px rgba(0,0,0,0.5));
		position: relative;

		&.visible {
			display: block;
		}

		&#notification, &#cookie-consent {
			.buttons {
				margin-top: 0.5rem;
				white-space: nowrap;
			}
	
			button {
				&:first-of-type {
					margin-right: 8px;
				}
			}
		}

		&#fn-modal {
			font-family: $serif;
		}

		&#cookie-consent {
			.cookie-message {
				text-align: left;

				a {
					color: $secondary;
					color: var(--secondary);

					&:hover {
						color: $secondary-variant;
						color: var(--secondary-variant);
					}
				}
			}
		}

		button {
			padding: 6px;
			margin: 0;
			font-weight: bold;
			text-transform: uppercase;
			display: inline-block;
			font-size: 0.7rem;;
			border-radius: 2px;
			background-color: $secondary;
			background-color: var(--secondary);
			color: $on-secondary;
			color: var(--on-secondary);

			&:hover {
				background-color: $secondary-variant;
				background-color: var(--secondary-variant);
			}
		}

		span {
			&.modal-close {
				position: absolute;
				cursor: pointer;
				pointer-events: all;
				background-position: center;
				background-repeat: no-repeat;
				background-image: url('/images/close.svg');
				top: 2px;
				right: 2px;
				width: 20px;
				height: 20px;

				@supports(mask-repeat: no-repeat) {
					background: var(--secondary) !important;
					mask-image: url('/images/close.svg');
					-webkit-mask-image: url('/images/close.svg');
					mask-repeat: no-repeat;
					-webkit-mask-repeat: no-repeat;
					mask-size: 10px;
					-webkit-mask-size: 10px;
					mask-position: center;
					-webkit-mask-position: center;
				}

				&:hover {
					background: var(--secondary-variant) !important;
				}
			}
		}
	}
}