html[data-theme="dark"] {
	.light-theme {
		display: none !important;
	}

	header {
		.logo {
			a {
				background-image: url('/images/logo_lg_white.svg');

				@include bp(mobile) {
					background-image: url('/images/logo_sm_white.svg');
				}
			}
		}
	}
}

html[data-theme="light"] {
	.dark-theme {
		display: none !important;
	}
}