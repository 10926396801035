header {
	background-color: $background;
	background-color: var(--background);
	@include bp(mobile) {
		display: block;
		position: fixed;
		width: 100%;
		background-color: rgba($background-opacity, 0.98);
		background-color: rgba(var(--background-opacity), 0.98);
		z-index: 3;
		top: 0;
		height: $header-height;
		border-bottom: 1px solid $surface;
		border-bottom: 1px solid var(--surface);
	}

	.logo {
		padding: 20px;
		border-bottom: 1px solid $surface;
		border-bottom: 1px solid var(--surface);
		@include bp(mobile) {
			border-bottom: none;
			padding: 15px;
		}

		a {
			display: block;
			height: 35px;
			width: 280px;
			margin: 0 auto;
			text-indent: -9999px;
			background-size: contain;
			background-repeat: no-repeat;
			background-position: center;
			background-image: url('/images/logo_lg.svg');

			@include bp(mobile) {
				width: 34px;
				background-image: url('/images/logo_sm.svg');
			}
		}
	}

	nav {
		@include bp(mobile) {
			display: none;
		}
	}
}

footer {
	display: none;
	position: fixed;
	z-index: 3;
	bottom: 0;
	width: 100%;
	height: $footer-height;
	background-color: rgba($background-opacity, 0.98);
	background-color: rgba(var(--background-opacity), 0.98);
	border-top: 1px solid $surface;
	border-top: 1px solid var(--surface);
	padding: 15px 30px;
	@include bp(mobile) {
		display: block;
	}
}

nav {
	font-family: $sans;
	font-weight: 600;
	font-size: 0.7rem;
	text-transform: uppercase;
	position: relative;
	justify-content: space-between;

	ul {
		display: flex;
		list-style: none;
		justify-content: center;
		margin: 0 auto;

		@include bp(lrg) {
			padding: 8px;
			border-bottom: 1px solid $surface;
			border-bottom: 1px solid var(--surface);
		}

		@include bp(mobile) {
			justify-content: space-between;
			width: 300px;
			max-width: 100%;
		}

		li {
			margin: 0 10px;
			@include bp(mobile) {
				margin: 0;
			}

			a {
				color: $on-surface;
				color: var(--on-surface);
				@include bp(mobile) {
					display: block;
					text-align: center;
					font-size: 0.6rem;
					font-weight: 400;
				}

				&:active {
					transition: ease opacity 0.2s;
					opacity: 0.8;
				}

				&.active {
					color: $primary;
					color: var(--primary);

					&:hover {
						color: $primary;
						color: var(--primary);
					}

					.icon {
						@include bp(mobile) {
							background: var(--primary) !important;
						}
					}
				}

				&:hover {
					color: $on-surface-variant;
					color: var(--on-surface-variant);
				}

				.icon {
					display: none;
					@include bp(mobile) {
						display: block;
						height: 26px;
						margin-bottom: 2px;
						background-repeat: no-repeat;
						background-position: center;

						@supports(mask-repeat: no-repeat) {
							background: var(--on-background) !important;
							mask-repeat: no-repeat;
							-webkit-mask-repeat: no-repeat;
							mask-size: 22px;
							-webkit-mask-size: 22px;
							mask-position: center top;
							-webkit-mask-position: center top;
						}
					}
	
					&.home {
						background-image: url('/images/home.svg');
						@supports(mask-repeat: no-repeat) {
							mask-image: url('/images/home.svg');
							-webkit-mask-image: url('/images/home.svg');
						}
					}
		
					&.about {
						background-image: url('/images/info.svg');
						@supports(mask-repeat: no-repeat) {
							mask-image: url('/images/info.svg');
							-webkit-mask-image: url('/images/info.svg');
						}
					}
		
					&.help {
						background-image: url('/images/help.svg');
						@supports(mask-repeat: no-repeat) {
							mask-image: url('/images/help.svg');
							-webkit-mask-image: url('/images/help.svg');
						}
					}

					&.settings {
						background-image: url('/images/settings.svg');
						@supports(mask-repeat: no-repeat) {
							mask-image: url('/images/settings.svg');
							-webkit-mask-image: url('/images/settings.svg');
						}
					}
				}	
			}
		}
	}
}